<template>
  <div class="Teacher">
    <page-info :page-title="teacherTitle"/>
    <div class="teacherContent">
      <div class="teacherLeft">
        <img src="../assets/images/teacher/aaa.png" alt="">
        <img src="../assets/images/teacher/aaas.png" alt="">
        <img src="../assets/images/teacher/aaasd.png" alt="">
      </div>
      <div class="teacherRight">
        <div class="teacherRight-content">
          <div class="teacherBox">
            <opacity-card-title :title="`老师备课排行榜（${nowMonth}月）`"/>
            <div class="teacherBoxChart">
              <div ref="e1El" style="width: 100%;height: 100%"></div>
            </div>
          </div>
          <div class="teacherBox">
            <opacity-card-title :title="`受家长好评老师排行榜(${nowMonth}月)`"/>
            <div class="teacherBoxChart" ref="e2El"></div>
          </div>
          <div class="teacherBox">
            <opacity-card-title :title="`优秀作品优秀率教师排行(${nowMonth}月)`"/>
            <div class="teacherBoxChart" ref="e3El"></div>
          </div>
        </div>
        <div>
          <img src="../assets/images/teacher/aas.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageInfo from "../components/PageInfo";
import OpacityCardTitle from "../components/OpacityCardTitle";
import moment from "moment";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import service from "../utils/axios";
import * as echarts from "echarts";
import {changePage} from "../utils/getPageTime";
import {useRouter} from "vue-router";

export default {
  name: "Teacher",
  components: {OpacityCardTitle, PageInfo},
  data() {
    return {
      teacherTitle: require('../assets/images/teacher/teacher_title.png'),
      nowMonth: moment().format('M'),
    }
  },
  setup() {
    let router = useRouter()
    let e1El = ref(null)
    let e2El = ref(null)
    let e3El = ref(null)

    let state = reactive({
      list1: [],
      list2: [],
      list3: [],
      e1: null,
      e2: null,
      e3: null,
      timeout: null,
    })

    const draw1 = async () => {
      let options = {
        xAxis: {type: 'value', axisLabel: {color: '#FFF'}},
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 40,
          bottom: 50,
          left: 70,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#FFF'
          },
          axisTick: {
            show: false
          },
        },
        dataset: {
          source: state.list1,
          dimensions: ['name', 'num'],
        },
        series: [
          {
            type: 'bar', barWidth: 18, barCategoryGap: 60, itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0.5,
                x2: 1,
                y2: 0.5,
                colorStops: [
                  {offset: 0, color: '#0F86EB'},
                  {offset: 0.5, color: '#1CD7E5'},
                  {offset: 1, color: '#23FFE2'},
                ],
                global: false,
              },
              borderRadius: [0, 20, 20, 0]
            },
            barMaxWidth: 18,
            label: {
              show: true,
              position: 'inside',
              color: '#FFF'
            }
          },
        ]
      }
      state.e1 = echarts.init(e1El.value);
      state.e1.setOption(options)
    }

    const draw2 = async () => {
      let options = {
        xAxis: {type: 'value', axisLabel: {color: '#FFF'}},
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 40,
          bottom: 50,
          left: 70,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#FFF'
          },
          axisTick: {
            show: false
          },
        },
        dataset: {
          source: state.list2,
          dimensions: ['name', 'num'],
        },
        series: [
          {
            type: 'bar', barWidth: 18, barCategoryGap: 60, itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0.5,
                x2: 1,
                y2: 0.5,
                colorStops: [
                  {offset: 0, color: '#F67265'},
                  {offset: 1, color: '#FFE375'},
                ],
                global: false,
              },
              borderRadius: [0, 20, 20, 0]
            },
            barMaxWidth: 18,
            label: {
              show: true,
              position: 'inside',
              color: '#FFF'
            },
          },
        ]
      }
      state.e2 = echarts.init(e2El.value);
      state.e2.setOption(options)
    }

    const draw3 = async () => {
      let options = {
        xAxis: {type: 'value', axisLabel: {color: '#FFF'}},
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: 40,
          bottom: 50,
          left: 70,
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            color: '#FFF'
          },
          axisTick: {
            show: false
          },
        },
        dataset: {
          source: state.list3,
          dimensions: ['name', 'num'],
        },
        series: [
          {
            type: 'bar', barWidth: 18, barCategoryGap: 60, itemStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0.5,
                x2: 1,
                y2: 0.5,
                colorStops: [
                  {offset: 0, color: '#433AC2'},
                  {offset: 1, color: '#B350FF'},
                ],
                global: false,
              },
              borderRadius: [0, 20, 20, 0]
            },
            barMaxWidth: 18,
            label: {
              show: true,
              position: 'inside',
              color: '#FFF'
            }
          },
        ]
      }
      state.e3 = echarts.init(e3El.value);
      state.e3.setOption(options)
    }

    const getData = async () => {
      const data1 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/teach/lessonPreparation')
      const data2 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/teach/parentEvaluation')
      const data3 = await service('/dwart/data_screen_manager/dataBoard/queryDataBoard/teach/studentWork')
      state.list1 = data1.sort((a, b)=> {return a.num - b.num});
      state.list2 = data2.sort((a, b)=> {return a.num - b.num});
      state.list3 = data3.sort((a, b)=> {return a.num - b.num});
      draw1()
      draw2()
      draw3()
      state.timeout = await changePage(router, '师资力量');
    }

    onMounted(async () => {
      await getData();
    })

    onUnmounted(() => {
      state.e1 && state.e1.clear();
      state.e2 && state.e2.clear();
      state.e3 && state.e3.clear();
      state.timeout && clearTimeout(state.timeout)
    })

    return {
      e1El,
      e2El,
      e3El,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/mixins";

.Teacher {
  @include pageMix;

  .teacherRight-content {
    height: 414px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    @media (max-width: 1280px) {
      height: 276px;
      margin-bottom: 15px;
    }
  }

  .teacherContent {
    padding: 30px 50px 25px;
    display: flex;
    //height: calc(100% - 90px);
    @media(max-width: 1280px) {
      padding: 20px 33px 17px;
      //height: calc(100% - 60px);
    }
  }

  .teacherLeft {
    width: 421px;
    margin-right: 38px;
    @media(max-width: 1280px) {
      width: 281px;
      margin-right: 25px;
    }

    img {
      width: 100%;
    }

    img + img {
      margin-top: 17px;
      @media(max-width: 1280px) {
        margin-top: 11px;
      }
    }
  }

  .teacherRight {
    flex: 1;
  }

  .teacherBox {
    flex: 1;
    height: 100%;
    @include backgroundMix;
    background-image: url("../assets/images/teacher/组 15713@2x.png");
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .teacherBox + .teacherBox {
    margin-left: 10px;

    @media(max-width: 1280px) {
      margin-left: 7px;
    }
  }

  .teacherBoxTitle {
    text-align: center;
    width: 260px;
    height: 43px;
    line-height: 43px;
    color: #FFF;
    display: inline-block;
    position: relative;
    font-size: 20px;
    @media(max-width: 1280px) {

    }
  }

  .teacherBoxTitle::before {
    content: "";
    background: linear-gradient(360deg, #0066CB 0%, rgba(0, 128, 255, 0) 100%);
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
  }

  .teacherBoxChart {
    flex: 1;
    width: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
