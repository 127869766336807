<template>
  <div class="OpacityCardTitle" :style="{width: width || (window.screen.width === 1280? 227: 260)}">
    <div class="titleText">
      {{title}}
    </div>
  </div>
</template>

<script>
export default {
  name: "OpacityCardTitle",
  props: {
    width: {
      required: false,
    },
    title: {
      required: false,
    }
  },
  data() {
    return {
      window,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.OpacityCardTitle {
  text-align: center;
  height: 43px;
  line-height: 43px;
  color: #FFF;
  display: inline-block;
  position: relative;
  font-size: 20px;
  @media(max-width: 1280px){
    height: 29px;
    line-height: 29px;
    font-size: 13px;
  }

  &::before {
    content: "";
    background: linear-gradient(360deg, #0066CB 0%, rgba(0, 128, 255, 0) 100%);
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 6px;
  }


  .titleText {
    display: inline-block;
    position: relative;
    z-index: 2;
    font-weight: bold;
  }
}
</style>
